import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import sfoairlines from '../assets/images/sfoairlines2.jpg'
import srn4 from '../assets/images/srn-4.jpg'

import { graphql } from 'gatsby'
import Banner from '../components/Banner'
import pic09 from '../assets/images/landry-gapangwa-737553-unsplash.jpg'

class Landing extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <html lang="en" />
          <title>HOVR - Hovercraft for San Francisco</title>
          <meta name="description" content="HOVR History Page" />
        </Helmet>
        <Banner
          bannerImage={this.props.data.bannerImage}
          bannerSubtitle="The history and future of hovercraft"
          bannerTitle="What are hovercraft?"
        />

        <div id="main">
          <section id="one">
            <div className="inner">
              <header className="major">
                <h3>About Hovercraft</h3>
              </header>
              <p>
                Hovercraft are low-flying aircraft held aloft on a cushion of
                air, just like a puck on an air-hockey table. This low flight
                enables them to traverse nearly any terrain, including open
                water, mudflats, ice sheets, tundra, deserts, beaches, fields,
                concrete, and much more. A skilled pilot can even mount ledges
                and walls, or travel down paved roads. Hovercraft come in a
                variety of shapes and sizes, from small ATV-sized recreational
                craft up to massive craft capable of carrying hundreds of
                passengers and dozens of cars.
              </p>
              <div>
                <header className="major">
                  <h3>The History of Hovercraft</h3>
                </header>
                <p>
                  <span className="image left">
                    <img
                      src={srn4}
                      alt="Hovercraft at San Francisco Airport in 1965"
                    />
                  </span>
                  Hovercraft were invented by British scientist Sir Christopher
                  Cockerell at his workshop in Southampton, England in the early
                  1950s. By experimenting with a coffee can, a cat food can, and
                  a hairdryer, he discovered that only a small amount of active
                  airflow was required to create significant lift.
                  <br /> British hovercraft technology developed rapidly through
                  the 1960s, with experiments proving their excellent
                  performance everywhere from ice sheets to deserts, culminating
                  in the massive SR.N4, the "Concorde of the Seas." This
                  impressive hover-ferry carried 418 passengers and 60 cars at
                  80 mph across the English Channel in all weathers. With the
                  increased focus on fuel economy brought about by the Oil
                  Crisis of 1973, hovercraft evolved into much quieter,
                  thriftier machines. Modern craft are powered by fuel-sipping
                  diesel engines instead of thirsty gas turbines. <br />
                  Today, most non-military hovercraft are found in service in
                  the Arctic, providing the only year-round access for residents
                  and work crews. Hovercraft are well-proven in some of the
                  harshest conditions on Earth and are perfectly suited to the
                  unique environment of the San Francisco Bay Area.
                </p>
              </div>
              <div>
                <header className="major">
                  <h3>Environmental Advantages</h3>
                </header>
                <p>
                  <span className="image right">
                    {' '}
                    <Link to="#" className="image">
                      <img src={pic09} alt="" />
                    </Link>
                  </span>
                  Hovercraft are better for the environment than conventional
                  ferries, as they move over the water instead of through it.
                  This means:
                </p>
                <ul>
                  <li>Low noise, minimal wildlife disturbance</li>
                  <li>
                    Low fuel consumption and carbon emissions, even at high
                    speed
                  </li>
                  <li>
                    Zero wake to erode shorelines or disturb other vessels
                  </li>

                  <li>Zero water pollution</li>
                </ul>
              </div>
              <div>
                <header className="major">
                  <h3>Service to SFO</h3>
                </header>
                <p>
                  <span className="image left">
                    <a
                      href="https://www.youtube.com/embed/x7yelF1oQ9A"
                      className="image"
                    >
                      <img
                        src={sfoairlines}
                        alt="Hovercraft at San Francisco Airport in 1965"
                      />
                    </a>
                  </span>
                  In 1965 service was demonstrated between the Port of San
                  Francisco, San Francisco Airport, and Oakland Airport. Crowds
                  gathered to watch demonstration flights from Lake Merritt. The
                  service ran for one year using two craft under a limited
                  exemption of the Jones Act by the US Commerce Department.
                  <br />
                  The service was popular and successful, but was not renewed
                  due to a lack of American expertise in hovercraft, a problem
                  HOVR exists to solve.
                </p>
              </div>
            </div>
          </section>
          <section id="two" className="spotlights">
            {/* <section>
              <a href="#" className="image">
                <img
                  src={srn4}
                  alt="Hovercraft at San Francisco Airport in 1965"
                />
              </a>
              <div className="content">
                <div className="inner">
                  <header className="major">
                    <h3>The History of Hovercraft</h3>
                  </header>
                  <p>
                    {/* <span className="image left">
                    <img
                      src={sfoairlines}
                      alt="Hovercraft at San Francisco Airport in 1965"
                    />
                  </span> 
                    Hovercraft were invented by British scientist Sir
                    Christopher Cockerell at his workshop in Southampton,
                    England in the early 1950s. By experimenting with a coffee
                    can, a cat food can, and a hairdryer, he discovered that
                    only a small amount of active airflow was required to create
                    significant lift.
                    <br /> British hovercraft technology developed rapidly
                    through the 1960s, with experiments proving their excellent
                    performance everywhere from ice sheets to deserts,
                    culminating in the massive SR.N4, the "Concorde of the
                    Seas." This impressive hover-ferry carried 418 passengers
                    and 60 cars at 80 mph across the English Channel in all
                    weathers. With the increased focus on fuel economy brought
                    about by the Oil Crisis of 1973, hovercraft evolved into
                    much quieter, thriftier machines. Modern craft are powered
                    by fuel-sipping diesel engines instead of thirsty gas
                    turbines. <br />
                    Today, most non-military hovercraft are found in service in
                    the Arctic, providing the only year-round access for
                    residents and work crews. Hovercraft are well-proven in some
                    of the harshest conditions on Earth and are perfectly suited
                    to the unique environment of the San Francisco Bay Area.
                  </p>
                </div>
              </div>
            </section>
          
            <section>
              <Link to="#" className="image">
                <img src={pic09} alt="" />
              </Link>
              <div className="content">
                <div className="inner">
                  <header className="major">
                    <h3>Environmental Advantages</h3>
                  </header>
                  <p>
                    Hovercraft are better for the environment than conventional
                    ferries, as they move over the water instead of through it.
                    This means:
                  </p>
                  <ul>
                    <li>Low noise, minimal wildlife disturbance</li>
                    <li>Minimal wildlife disturbance</li>
                    <li>
                      Low fuel consumption and carbon emissions, even at high
                      speed
                    </li>
                    <li>Zero water pollution</li>
                  </ul>
                </div>
              </div>
            </section> */}
            {/* <section>
              <a
                href="https://www.youtube.com/embed/x7yelF1oQ9A"
                className="image"
              >
                <img
                  src={sfoairlines}
                  alt="Hovercraft at San Francisco Airport in 1965"
                />
              </a>
              <div className="content">
                <div className="inner">
                  <header className="major">
                    <h3>Service to SFO</h3>
                  </header>
                  <p>
                    In 1965 service was demonstrated between the Port of San
                    Francisco, San Francisco Airport, and Oakland Airport.
                    Crowds gathered to watch demonstration flights from Lake
                    Merritt. The service ran for one year using two craft under
                    a limited exemption of the Jones Act by the US Commerce
                    Department. The service was popular and successful, but was
                    not renewed due to a difficulty sourcing American made
                    hovercraft.
                  </p>
                </div>
              </div>
            </section> */}
          </section>
        </div>
      </Layout>
    )
  }
}

export default Landing

export const pageQuery = graphql`
  query {
    bannerImage: file(relativePath: { eq: "sfoairlines1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
